import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import styled from "@emotion/styled";

import useDowntimeReasonsMutationQuery from "api/downtimeReasons/useDowntimeReasonsMutationQuery";

import { IconX } from "@tabler/icons-react";
import {
  DownTimeReasonsOption,
  useDownTimeReasonsAutoComplete,
  useDownTimeReasonsCode,
} from "api/downtimeReasons/useDowntimeReasonsGetQuery";
import { DowntimeReasonsCreateForm } from "components/common/form/downtimeReasons/CreateForm";
import { customFunctions } from "config/customFunctions";
import { useModal } from "context/ModalStackManager";

interface DowntimeReasonsProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
  group: string;
}

export interface DowntimeReasonsAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (DowntimeReasonsCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
  operationCode?: string;
  downtimeReasonCode?: Array<string>;
}

// 기능 사용 시 키 존재 여부를 확인
const isAddDownTimeReasonEnabled = customFunctions["ADD_DOWNTIME_REASON"];

// 입력값: value (DowntimeReasons 모델의 code)
// 출력값: onChange (DowntimeReasons 모델의 code)

export const DowntimeReasonsAutoComplete = (
  params: DowntimeReasonsAutoCompleteProps
) => {
  const {
    value: DowntimeReasonsCode,
    onChange,
    maxDropdownHeight,
    width,
    operationCode,
    downtimeReasonCode,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useDownTimeReasonsAutoComplete(
    focused,
    searchKeyword
  );

  const { data: initialOptions } = useDownTimeReasonsCode(
    !!DowntimeReasonsCode,
    DowntimeReasonsCode ?? null
  );

  let selectedDowntimeReasons = initialOptions?.find(
    (DowntimeReasons) => DowntimeReasons.value === DowntimeReasonsCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedDowntimeReasons = options?.find(
      (DowntimeReasons) => DowntimeReasons.value === e
    );
    onChange && onChange(e);
  };

  const SelectDowntimeReasons = forwardRef<
    HTMLDivElement,
    DowntimeReasonsProps
  >(({ label: name, value: code, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Flex direction="row" justify="space-between" align="center">
        <Flex align={"center"}>
          <Text>{name}</Text>
          <Text fz="xl">(비가동 코드: {code})</Text>
        </Flex>
      </Flex>
    </div>
  ));

  return (
    <Select
      styles={{
        input: {
          width: width,
        },
      }}
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      initiallyOpened={true}
      inputContainer={(children: ReactNode) => (
        <div>
          {children}
          <DowntimeReasonsInfoLabel>
            {selectedDowntimeReasons?.value &&
              "Code: " + selectedDowntimeReasons?.value}
          </DowntimeReasonsInfoLabel>
        </div>
      )}
      value={DowntimeReasonsCode}
      itemComponent={SelectDowntimeReasons}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])]
        // 해당 비가동사유가 설비에 설정되어 있거나, 해당 공정에 속하는 비가동사유 또는 공정이 할당되지 않은 비가동 사유만 필터링
        .filter(
          (option) => downtimeReasonCode && downtimeReasonCode?.length > 0 ? (option.group === operationCode || option.group === "공정 없음") && (downtimeReasonCode && downtimeReasonCode.includes(option.value)) :
            (option.group === operationCode || option.group === "공정 없음")
        )
        .reduce(
          (unique: DowntimeReasonsProps[], option: DowntimeReasonsProps) => {
            return unique.some((u) => u.value === option.value)
              ? unique
              : [...unique, option];
          },
          []
        )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 250}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={DowntimeReasonsInfo({
        DowntimeReasons: selectedDowntimeReasons as DownTimeReasonsOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={isAddDownTimeReasonEnabled && AddNewDowntimeReasons({
        reasoneName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const DowntimeReasonsInfo = (params: {
  DowntimeReasons?: DownTimeReasonsOption;
  onChange: (DowntimeReasonsCode: string | null) => void;
}) => {
  const { DowntimeReasons, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return DowntimeReasons?.value ? (
    <DowntimeReasonsInfoLabel>
      {/* ({DowntimeReasons.value})  */}
      <IconX size="2.5rem" onClick={clearHandler} />{" "}
    </DowntimeReasonsInfoLabel>
  ) : null;
};

const AddNewDowntimeReasons = (params: {
  reasoneName: string;
  onChange: (DowntimeReasonsCode: string) => void;
}) => {
  const { reasoneName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const { mutate: postMutate } = useDowntimeReasonsMutationQuery("create");

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        downtimeReasonsGetRequest: {
          code: values.code!,
          name: values.name!,
        },
      },
      {
        onSuccess: () => {
          alert("비가동 사유 생성 성공");
          closeModal(values);
        },
      }
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <DowntimeReasonsCreateForm
        name={reasoneName}
        onSubmit={onCloseHandler}
      />,
      "",
      "신규 비가동 사유 등록"
    ).then((result) => {
      onChange(result.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 비가동 사유({reasoneName}) 등록
    </Button>
  );
};
const DowntimeReasonsInfoLabel = styled.div`
  font-size: 1.5rem;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  position: absolute;
`;
