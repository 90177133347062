import { Button, Flex, Loader, Paper, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import useProductionPlansProductionPlanIdSplitProductLotPost from "api/splitProductLot/useSplitProductLot";
import axios from "axios";
import { useModal } from "context/ModalStackManager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPrinterCodeByUserWithWhoami } from "utils/checkAuth";
import { setToLocaleString } from "utils/unitMark";

interface PalletBarcodePrintBoxProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined;
    lotData: ItemsGet200ResponseRowsInnerLotsInner | undefined;
    quantity?: string | undefined;
}

export const PalletBarcodePrintBox = (params: PalletBarcodePrintBoxProps) => {

    const { workData, lotData, quantity } = params;

    const { closeModal } = useModal();
    const [barcodeLoading, setBarcodeLoading] = useState<boolean>(false);
    const { isLoading } = useProductionPlansProductionPlanIdSplitProductLotPost();
    const { t } = useTranslation();

    const onPrintPallet = async () => {
        setBarcodeLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INVENTORY}`, {
                company_code: process.env.REACT_APP_COMPANY_CODE,
                lot_name: [lotData?.name],
                printer_code: await getPrinterCodeByUserWithWhoami(),
                quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
                location_code: [
                    workData?.targetLocationCode
                    ?? workData?.equipment?.targetLocationCode
                    ?? workData?.routingOutsourceData?.to_location_code
                    ?? workData?.routingData?.operation?.toLocationCode
                ],
                item_code: [workData?.item?.code],
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setBarcodeLoading(false);
            closeModal({});
        }
    }

    if (isLoading || barcodeLoading) return <Flex w="100%" h="100%" justify="center" align="center"><Loader size="20rem" /></Flex>

    return (
        <>
            {workData ? (
                <Flex w="50rem" direction="column" gap="sm">
                    <Paper withBorder p="md">
                        <Text fz="4rem">{t("품목명")} : {workData?.item?.name}</Text>
                        <Text fz="4rem">{t("규격")} : {workData?.item?.spec ?? "-"}</Text>
                        <Text fz="4rem">{t("양품 수량")} : {setToLocaleString(quantity? quantity : workData.currentLotSummary?.totalQuantity)}</Text>
                        <Text fz="4rem">{t("LOT명")} : {lotData?.name}</Text>
                    </Paper>
                    <Flex w="100%" gap="sm">
                        <Button w="50%" size="xl" fz="2rem" color="indigo" onClick={onPrintPallet}>
                            {t("출력")}
                        </Button>
                        <Button w="50%" size="xl" fz="2rem" color="teal" onClick={() => closeModal({})}>{t("취소")}</Button>
                    </Flex>
                </Flex>
            ) :
                <Flex direction="column" gap="sm">
                    <Text fz="3rem">
                        {t("현재 로트에 대해 생산된 실적이 없습니다.")}
                    </Text>
                    <Flex gap="sm" justify={'flex-end'}>
                        <Button size="xl" color="red" onClick={() => closeModal({})}>{t("아니오")}</Button>
                    </Flex>
                </Flex>
            }
        </>
    )
}