import { Badge, Button, Flex, Modal, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useWorkLogsGet } from "api/logs/useWorkLogsGetQuery";
import { BaseText } from "components/common/Text/baseText";
import { WorkTable } from "components/common/table/workTable";
import { BaseTitle } from "components/common/title/BaseTitle";
import { getColorOfLogType, getKoreanWorkLogType } from "constants/workLogType";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormButtonBox } from "../FormButtonBox";
import { FormMain } from "../FormMain";
import { FormWrapper } from "../FormWrapper";
import { LogsFormProps } from "./types";
import { IconTrash } from "@tabler/icons-react";
import { useGetWorkData } from "../../../../hooks/useGetWorkData";
import { PalletBarcodePrintBox } from "../../../pages/work/workPalletSeparate/palletBarcodePrintBox";

const Logs = Object.assign({}, FormMain, {
    Button: Button,
    Description: BaseTitle,
    Text: BaseText,
    Table: WorkTable,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Pagination: Pagination
});

export function SplitLogsForm(params: LogsFormProps) {

    const { workId } = params;
    const [activePage, setPage] = useState<number>(1);
    const [selectedLog, setSelectedLog] = useState<any>(null);
    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const { t } = useTranslation();
    
    const { data: WorkLog } = useWorkLogsGet({
        query: {
            $and: [{ workId: { $eq: workId ?? 0 } },
            { parentWorkLogId: { $eq: null } },
            {
                $or: [
                    { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.SPLIT_LOT } },
                ],
            },
            {
                $or: [
                    { description: { $ne: 'ACTION' } },
                    { description: { $eq: null } }
                ]
            },
            ]
        },
        sort: "-id",
        page: activePage,
    })

    const { workData } = useGetWorkData();
    
    const handlePrintClick = (log: any) => {
        setSelectedLog(log);
        setModalOpened(true);
    }

    return (
        <Logs.Wrapper>
            <Logs.Description c={'#010000'} size={'sm'} fw={400}>
                {t('파레트분리 로그를 확인합니다.')}
            </Logs.Description>
            <Logs.Table
                fontSize={'sm'}
                withBorder={false}
                withColumnBorders={false}
                theadColor={"#FFFFFF"}
                flex={[1, 2, 1, 2, 1, 1]}
                trColor={"#000000"}
                columns={[
                    t("로그 번호"),
                    t("일시"),
                    t("타입"), 
                    t("로트명"), 
                    t("수량"), 
                    t("작업자"), 
                    t("출력"), 
                ]}
                data={WorkLog?.rows?.map((log) => {

                    const logType = getKoreanWorkLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const colorOfLogType = getColorOfLogType(log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum)
                    const lotName = log?.lotData?.name ?? '-';

                    return [
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.id ?? '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{dayjs(log?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Logs.Text>,
                        <Badge size="xl" color={colorOfLogType}>{t(logType)}</Badge>,
                        <Logs.Text c={'dark'} size={'xl'}>{lotName}</Logs.Text>,
                        <Logs.Text align="right" c={'dark'} size={'xl'}>{log?.quantity ? `${log?.quantity} ${log.unitText}` : '-'}</Logs.Text>,
                        <Logs.Text c={'dark'} size={'xl'}>{log?.creatorUserName}</Logs.Text>,
                        log.alreadyCanceledWorkLog ?
                        <Logs.Text c={'grape'} size={'xl'}>{t("취소됨")}</Logs.Text>
                        :
                        <Logs.Button
                            bg={"inherit"}
                            styles={{
                                'root': {
                                    '&:hover': {
                                        background: 'inherit'
                                    }
                                }
                            }}
                            onClick={() => handlePrintClick(log)}>

                            <IconTrash color="red" />
                            <Logs.Text pl={'1rem'} c={'red'} size={'xl'}>{t("출력")}</Logs.Text>
                        </Logs.Button>
                    ] as string[] | React.ReactElement[];
                })}
            />
            <Flex justify={'center'}>
                <Logs.Pagination
                    size={'xl'}
                    value={activePage}
                    onChange={setPage}
                    total={WorkLog?.totalPages as number}
                />
            </Flex>
            <Modal
                opened={modalOpened}
                onClose={() => setModalOpened(false)}
                title={t("바코드 출력")}
                size="auto"
            >
                {selectedLog && (
                    <PalletBarcodePrintBox
                        workData={workData}
                        lotData={selectedLog.lotData}
                        quantity={selectedLog.quantity}
                    />
                )}
            </Modal>
        </Logs.Wrapper>
    );
}